<template>
  <div>
    <header-slot>
      <template #actions>
        <!-- Button Request Equipment -->
        <b-button @click="openVendorModal()" variant="outline-success" class="mr-1">
          <feather-icon
                    icon="PlusIcon"
                    size="15"
                    class="text-success mr-1"
                  />CREATE VENDOR
        </b-button>
      </template>
    </header-slot>
    <!-- Dynamic Route -->
    <b-nav card-header pills class="m-0">
      <!-- Button Equipment -->
      <b-nav-item
        :to="{ name: 'vendors-peru' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >PERÚ</b-nav-item
      >
      <!-- Button Equipment Request -->
      <b-nav-item
        :to="{ name: 'vendors-usa' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        USA
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view></router-view>
    </b-card>
    <vendor-modal v-if="showVendorModal" @closeModal="closeModal()" :typeModal="'ADD'"></vendor-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import VendorModal from "./views/modals/VendorModal.vue"
export default {
  components: {
    "vendor-modal": VendorModal,
  },
  data() {
    return {
      showVendorModal: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    openVendorModal(){
      this.showVendorModal = true;
    },
    closeModal(){
      this.showVendorModal = false;
    }
  },
};
</script>
